import { Page, View, Document, Font } from "@react-pdf/renderer";
import { HorizontalLine, Typography, PageNumber, Row, Stack } from "./utilities";
import { VendorHeader, VendorHeaderEverfresh, VendorHeaderGlory } from "./VendorHeader";
import { Bankdetails } from "./Bankdetails";
Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

export const CreditNotePDF = ({ order, orderDate, language }) => {
  if (order.id === undefined) return null;

  const tableColumns = [
    {
      title: "#",
      dataKey: "index",
      key: "index",
      span: 0.25,
      align: "left",
      render: (_, record, index) => {
        return record && `${index + 1}`;
      },
    },
    {
      title: "Description",
      dataKey: "name",
      key: "name",
      span: 5,
      align: "left",
      render: (_, record) => {
        if (record.isOpenItem) return record.name;
        const name = language === "single" ? record.product.name_EN : record.product.name;
        if (record.pickingStatus === "na")
          return [name, "\nThis item is not available at this moment"];

        if (record.pickingStatus === "replace")
          return [name, "\nThis is a replace item with no charge"];
        return name;
      },
    },

    {
      title: "Quantity",
      dataKey: "quantity",
      key: "quantity",
      span: 1,
      align: "left",
    },
    {
      title: "Unit Price",
      dataKey: "unitPrice",
      key: "unitPrice",
      span: 1.75,
      align: "right",
      render: (value) => `$${value}`,
    },
    {
      title: "Line Total",
      dataKey: "lineTotal",
      key: "lineTotal",
      span: 2,
      align: "right",
      render: (_, record) => `$${(record.unitPrice * record.quantity).toFixed(2)}`,
    },
  ];

  if (order?.customer?.invoiceTemplate === "GLORY_FRESH") {
    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 32,
            paddingBottom: 40,
            paddingHorizontal: 32,
            backgroundColor: "#fff",
          }}
        >
          <Row>
            <Stack
              sx={{
                width: "50%",
                alignItems: "flex-start",
              }}
            >
              <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
                {`Credit Note${order?.status === " draft" ? "(Draft)" : ""}`}
              </Typography>
              <Typography sx={{ textAlign: "left" }}>CN NO: {`#${order?.name}`}</Typography>
              <Typography sx={{ textAlign: "left" }}>ABN: {`13663504434`}</Typography>
              <Typography sx={{ textAlign: "left" }}>ISSUE DATE: {orderDate}</Typography>
              {/* <Typography sx={{ textAlign: "left" }}>DUE DATE: {orderDate}</Typography> */}
            </Stack>
            <VendorHeaderGlory />
          </Row>

          <Row sx={{ marginTop: 24, marginBottom: 24 }}>
            <Stack
              sx={{
                width: "65%",
                marginRight: "8px",
              }}
            >
              <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
                {order.customer?.name}
              </Typography>
              <Typography>(Add) {order.customer?.deliveryAddress}</Typography>
              <Typography>(Phone) </Typography>
              <Typography type="header">{order.notes}</Typography>
            </Stack>
          </Row>

          {/* table header  */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              columnGap: "4px",
            }}
          >
            {tableColumns.map((th) => {
              const columnWidth = `${(th.span / 12) * 100}%`;
              return (
                <Typography
                  key={th.key}
                  sx={{
                    fontWeight: "bold",
                    width: columnWidth,
                    textAlign: th.align,
                    margin: "0 4px",
                  }}
                >
                  {th.title}
                </Typography>
              );
            })}
          </View>
          <HorizontalLine />

          {/* table body  */}

          {order?.lineItems &&
            order.lineItems.map((item, index) => {
              return (
                <View
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    columnGap: "4px",
                    padding: "4px",
                    borderBottom: "0.35px solid lightgrey",
                  }}
                >
                  {tableColumns.map((th) => {
                    const columnWidth = `${(th.span / 12) * 100}%`;
                    const value = item[th.dataKey];
                    const strike =
                      (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                      (item.pickingStatus === "na" || item.pickingStatus === "replace");
                    const textValue = th.render ? th.render(value, item, index) : value;
                    return (
                      <Typography
                        key={th.key}
                        sx={{
                          width: columnWidth,
                          margin: "0 4px",
                          textAlign: th.align,
                          fontFamily: "Microsoft YaHei",
                          textDectoration: strike ? "line-through" : "none",
                        }}
                      >
                        {typeof textValue === "string" ? (
                          textValue
                        ) : (
                          <>
                            {textValue[0]}
                            <Typography
                              key={th.key + "_notes"}
                              sx={{
                                width: columnWidth,
                                margin: "0 4px",
                                textAlign: th.align,
                                fontFamily: "Microsoft YaHei",
                                textDectoration: strike ? "line-through" : "none",
                                color: "grey",
                              }}
                            >
                              {textValue[1]}
                            </Typography>
                          </>
                        )}
                      </Typography>
                    );
                  })}
                </View>
              );
            })}

          <HorizontalLine />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Stack>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "3px 0",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>Subtotal:</Typography>
                <Typography>${order?.totalWithoutGst}</Typography>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "3px 0",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>GST:</Typography>
                <Typography>$0.00</Typography>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "5px 0",
                }}
              >
                <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                  Total:
                </Typography>
                <Typography type="header" sx={{ fontSize: 16 }}>
                  ${order?.total}
                </Typography>
              </View>

              {order?.payments.map((payment) => {
                return (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      padding: "3px 0",
                    }}
                  >
                    <Typography sx={{ marginRight: "4px" }}>{payment.name}</Typography>
                    <Typography>${payment.amount}</Typography>
                  </View>
                );
              })}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "5px 0",
                }}
              >
                <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                  Amount Due:
                </Typography>
                <Typography type="header" sx={{ fontSize: 16 }}>
                  ${order?.balanceDue}
                </Typography>
              </View>
            </Stack>
          </View>

          <View>
            <Typography type="header">Glory Fresh Produce</Typography>
            <Typography>(Add) 1 Schofield St, Riverwood NSW 2210</Typography>
            <Typography>(Email) gloryfresh.office@gmail.com</Typography>
            <Typography>(Instagram) gloryfreshproduce</Typography>
            <Typography>(Phone) 0400 628 618</Typography>
            <Typography>(ABN) 13663504434</Typography>
          </View>

          <PageNumber />
        </Page>
      </Document>
    );
  }

  if (order?.customer?.invoiceTemplate === "EVER_FRESH") {
    return (
      <Document>
        <Page
          size="A4"
          style={{
            paddingTop: 32,
            paddingBottom: 40,
            paddingHorizontal: 32,
            backgroundColor: "#fff",
          }}
        >
          <Row>
            <Stack
              sx={{
                width: "50%",
                alignItems: "flex-start",
              }}
            >
              <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
                {`Credit note${order?.status === " draft" ? "(Draft)" : ""}`}
              </Typography>
              <Typography sx={{ textAlign: "left" }}>CN NO: {`#${order?.name}`}</Typography>
              <Typography sx={{ textAlign: "left" }}>ABN: {`13663504434`}</Typography>
              <Typography sx={{ textAlign: "left" }}>ISSUE DATE: {orderDate}</Typography>
              {/* <Typography sx={{ textAlign: "left" }}>DUE DATE: {orderDate}</Typography> */}
            </Stack>
            <VendorHeaderEverfresh />
          </Row>

          <Row sx={{ marginTop: 24, marginBottom: 24 }}>
            <Stack
              sx={{
                width: "65%",
                marginRight: "8px",
              }}
            >
              <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
                {order.customer?.name}
              </Typography>
              <Typography>(Add) {order.customer?.deliveryAddress}</Typography>
              <Typography>(Phone) </Typography>
              <Typography type="header">{order.notes}</Typography>
            </Stack>
          </Row>

          {/* table header  */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              columnGap: "4px",
            }}
          >
            {tableColumns.map((th) => {
              const columnWidth = `${(th.span / 12) * 100}%`;
              return (
                <Typography
                  key={th.key}
                  sx={{
                    fontWeight: "bold",
                    width: columnWidth,
                    textAlign: th.align,
                    margin: "0 4px",
                  }}
                >
                  {th.title}
                </Typography>
              );
            })}
          </View>
          <HorizontalLine />

          {/* table body  */}

          {order?.lineItems &&
            order.lineItems.map((item, index) => {
              return (
                <View
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    columnGap: "4px",
                    padding: "4px",
                    borderBottom: "0.35px solid lightgrey",
                  }}
                >
                  {tableColumns.map((th) => {
                    const columnWidth = `${(th.span / 12) * 100}%`;
                    const value = item[th.dataKey];
                    const strike =
                      (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                      (item.pickingStatus === "na" || item.pickingStatus === "replace");
                    const textValue = th.render ? th.render(value, item, index) : value;
                    return (
                      <Typography
                        key={th.key}
                        sx={{
                          width: columnWidth,
                          margin: "0 4px",
                          textAlign: th.align,
                          fontFamily: "Microsoft YaHei",
                          textDectoration: strike ? "line-through" : "none",
                        }}
                      >
                        {typeof textValue === "string" ? (
                          textValue
                        ) : (
                          <>
                            {textValue[0]}
                            <Typography
                              key={th.key + "_notes"}
                              sx={{
                                width: columnWidth,
                                margin: "0 4px",
                                textAlign: th.align,
                                fontFamily: "Microsoft YaHei",
                                textDectoration: strike ? "line-through" : "none",
                                color: "grey",
                              }}
                            >
                              {textValue[1]}
                            </Typography>
                          </>
                        )}
                      </Typography>
                    );
                  })}
                </View>
              );
            })}

          <HorizontalLine />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Stack>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "3px 0",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>Subtotal:</Typography>
                <Typography>${order?.totalWithoutGst}</Typography>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "3px 0",
                }}
              >
                <Typography sx={{ marginRight: "4px" }}>GST:</Typography>
                <Typography>$0.00</Typography>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "5px 0",
                }}
              >
                <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                  Total:
                </Typography>
                <Typography type="header" sx={{ fontSize: 16 }}>
                  ${order?.total}
                </Typography>
              </View>

              {order?.payments.map((payment) => {
                return (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      padding: "3px 0",
                    }}
                  >
                    <Typography sx={{ marginRight: "4px" }}>{payment.name}</Typography>
                    <Typography>${payment.amount}</Typography>
                  </View>
                );
              })}

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  padding: "5px 0",
                }}
              >
                <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                  Amount Due:
                </Typography>
                <Typography type="header" sx={{ fontSize: 16 }}>
                  ${order?.balanceDue}
                </Typography>
              </View>
            </Stack>
          </View>

          <View>
            <Typography type="header">Glory Fresh Produce</Typography>
            <Typography>(Add) 1 Schofield St, Riverwood NSW 2210</Typography>
            <Typography>(Email) gloryfresh.office@gmail.com</Typography>
            <Typography>(Instagram) gloryfreshproduce</Typography>
            <Typography>(Phone) 0400 628 618</Typography>
            <Typography>(ABN) 13663504434</Typography>
          </View>

          <PageNumber />
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 40,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <VendorHeader />
          <Stack
            sx={{
              width: "50%",
              alignItems: "flex-end",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
              {order?.status === "draft" ? `DRAFT #${order?.name}` : `Credit Note #${order?.name}`}
            </Typography>
            <Typography sx={{ textAlign: "right" }}>Issue date: {orderDate}</Typography>
          </Stack>
        </Row>

        <Row sx={{ marginTop: 24, marginBottom: 24 }}>
          <Stack
            sx={{
              width: "50%",
              borderRadius: "5px",
              border: "1px solid #000",
              padding: "8px",
              marginRight: "8px",
            }}
          >
            <Typography>To customer: </Typography>
            <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
              {order.customer?.name}
            </Typography>
            <Typography>Billing Add: {order.customer?.billingAddress}</Typography>
            <Typography>Email: {order.customer?.email}</Typography>
            <Typography>Phone: {order.customer?.phone}</Typography>
          </Stack>

          <Stack
            sx={{
              borderRadius: "5px",
              border: "1px solid #000",
              width: "50%",
              padding: "8px",
            }}
          >
            <Typography>Delivery Address</Typography>
            <Typography type="header">
              {order.deliveryAddress || order.customer?.deliveryAddress}
            </Typography>
            <Typography>Note: {order.notes}</Typography>
          </Stack>
        </Row>

        {/* table header  */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 12) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {/* table body  */}

        {order?.lineItems &&
          order.lineItems.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "4px",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 12) * 100}%`;
                  const value = item[th.dataKey];
                  const strike =
                    (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                    (item.pickingStatus === "na" || item.pickingStatus === "replace");
                  const textValue = th.render ? th.render(value, item, index) : value;
                  return (
                    <Typography
                      key={th.key}
                      sx={{
                        width: columnWidth,
                        margin: "0 4px",
                        textAlign: th.align,
                        fontFamily: "Microsoft YaHei",
                        textDectoration: strike ? "line-through" : "none",
                      }}
                    >
                      {typeof textValue === "string" ? (
                        textValue
                      ) : (
                        <>
                          {textValue[0]}
                          <Typography
                            key={th.key + "_notes"}
                            sx={{
                              width: columnWidth,
                              margin: "0 4px",
                              textAlign: th.align,
                              fontFamily: "Microsoft YaHei",
                              textDectoration: strike ? "line-through" : "none",
                              color: "grey",
                            }}
                          >
                            {textValue[1]}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  );
                })}
              </View>
            );
          })}

        <HorizontalLine />

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Stack>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "3px 0",
              }}
            >
              <Typography sx={{ marginRight: "4px" }}>Subtotal:</Typography>
              <Typography>${order?.totalWithoutGst}</Typography>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "3px 0",
              }}
            >
              <Typography sx={{ marginRight: "4px" }}>GST:</Typography>
              <Typography>$0.00</Typography>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "5px 0",
              }}
            >
              <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                Total:
              </Typography>
              <Typography type="header" sx={{ fontSize: 16 }}>
                ${order?.total}
              </Typography>
            </View>

            {order?.payments.map((payment) => {
              return (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    padding: "3px 0",
                  }}
                >
                  <Typography sx={{ marginRight: "4px" }}>{payment.name}</Typography>
                  <Typography>${payment.amount}</Typography>
                </View>
              );
            })}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "5px 0",
              }}
            >
              <Typography type="header" sx={{ fontSize: 16, marginRight: "4px" }}>
                Balance Due:
              </Typography>
              <Typography type="header" sx={{ fontSize: 16 }}>
                ${order?.balanceDue}
              </Typography>
            </View>
          </Stack>
        </View>

        <View>
          <Bankdetails
            total={order?.total}
            balanceDue={order?.balanceDue}
            balance={order?.customer?.accountBalance}
          />
        </View>

        <PageNumber />
      </Page>
    </Document>
  );
};
